
import { useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";


const GoogleBuckets = (props) => {
    
    
    const navigate = useNavigate()
    const [buckets, setBuckets] = useState(null)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [projectId, setProjectId] = useState(null)

    
    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProjectId(props.projectId)
    }, [])
  
    useEffect(() => {
        setBuckets(props.data)
    }, [props.data])

    

    const routeChange = (datasetId) => {
        navigate(`/gcp/bigquery/${projectId}/${datasetId}`, { state: 
            {   AWSAccessToken: AWSAccessToken,
                GCPAccessToken: GCPAccessToken,
                projectId: projectId,
                datasetId: datasetId,
                type: 'Tables',
                blob: '',
                key: Date.now()
            }
          })
    }

    return ( 
        <div className="content-list">
            {
                buckets
                ? buckets.map((p, index) => (
                    <div className="content-preview" key={index}>
                        <a onDoubleClick={() => routeChange(p._properties.datasetReference.datasetId)}>
                            <div className="row">        
                                <div className="column" >
                                    <p>{p._properties.datasetReference.datasetId}</p>
                                </div>
                                <div className="column" >
                                    <p>{p._properties.location}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default GoogleBuckets;