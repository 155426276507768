const GoogleProjectsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>Project Name</b></p>
            </div>
            <div className="column" >
                <p><b>Project ID</b></p>
            </div>
            
            <div className="column" >
                <p><b>Project Number</b></p>
            </div>
        </div>
     );
}
 
export default GoogleProjectsFields;