const AWSBlobsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>Blobs Name</b></p>
            </div>
            <div className="column" >
                <p><b>Blobs Size</b></p>
            </div>
            
            <div className="column" >
                <p><b>Last Updated</b></p>
            </div>
        </div>
     );
}
 
export default AWSBlobsFields;