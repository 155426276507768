const GoogleBucketsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>Bucket Name</b></p>
            </div>
            <div className="column" >
                <p><b>Bucket ID</b></p>
            </div>
            
            <div className="column" >
                <p><b>Storage Class</b></p>
            </div>
        </div>
     );
}
 
export default GoogleBucketsFields;