
import GoogleLogin from './GCP/GoogleLogin';
import AwsLogin from './AWS/AWSLogin';
import './Login.css'

const Login = () => {
    return ( 
        <div className="Login">
            <div className="LoginBox" >
                <h4>Log In</h4>
                <div className="LoginButtons">
                    <div className="LoginButtons-container">
                        <div className="row">
                            <GoogleLogin></GoogleLogin>
                        </div>

                        <div className="row">
                            <AwsLogin></AwsLogin>
                        </div>
                    </div>
                    
                </div>
                
                
            </div>

        </div>
    );
}
 
export default Login;