
import { useState, useEffect } from 'react'
import '../../Components/UserToolbar.css'
import axios from 'axios'
import FormData from 'form-data'
import { useParams } from "react-router-dom";

const GoogleBigQueryToolbar = (props) => {


    const [type, setType] = useState(null)
    const [content, setContent] = useState(null)
    const [target, setTarget] = useState(null)
    const { projectId, datasetId } = useParams();

    useEffect(() => {
        setType(props.type)
    }, [props.type])

    useEffect(() => {
        setTarget(props.target)
    }, [props.target])
    

    useEffect(() => {
        if(content){
            const data = new FormData();
            Array.from(content).forEach(file => {
                data.append('file', file)
            })

            
            
            axios.post('/api/gcloud/bigquery/upload', data, {
                headers: {
                 'accessToken': JSON.stringify(props.accessToken),
                 'projectId': projectId,
                 'datasetId': datasetId,
                 'content-type': 'multipart/form-data' 
                }})
        }
    }, [content])

    

    const uploadFilesButtonPress = () => {
        document.getElementById("inputFiles").value = ''
        document.getElementById("inputFiles").addEventListener("onChange", event => {uploadFiles(event)}, {once: true})
        document.getElementById("inputFiles").click()
    }


    const uploadFiles = (e) => {
        setContent(e.target.files)
    }

    const transferBQtoS3 = () => {
        props.transferButtonTrigger()
    }


    return (  
        
        <div className="toolbar">
            {(() => {
                    if (type === 'Tables' && target) {
                    return (
                        <div>
                                <input id="inputFiles" className="input" type="file" onChange={uploadFiles} multiple hidden/>
                                <button id="buttonFiles" className="button" onClick={uploadFilesButtonPress}>Upload Local Files</button>
                                <button id="buttonFiles" className="button" onClick={transferBQtoS3}>Transfer BQ to S3</button>
                            {/* {
                                target.length > 0 ? 
                                (
                                <>
                                <button id="buttonFiles" className="button" >Delete Files</button>
                                </>
                                ) :
                                (null)
                            } */}
                            
                            
                            <div className="clear"></div>
                        </div>
                    )
                    } else {
                    return (
                        null
                    )
                    }
                })()}
            
        </div>
    );
}
 
export default GoogleBigQueryToolbar;