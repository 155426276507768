const AWSBucketsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>Bucket Name</b></p>
            </div>
            <div className="column" >
                <p><b>Date Created</b></p>
            </div>
            
        </div>
     );
}
 
export default AWSBucketsFields;