const GoogleBucketsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>DatasetId</b></p>
            </div>
            <div className="column" >
                <p><b>Location</b></p>
            </div>
        </div>
     );
}
 
export default GoogleBucketsFields;