import droplets from '../Images/droplets.png'
import '../Components/Navbar.css'
import GoogleLogin from '../GCP/GoogleLogin'
import { useNavigate, useLocation } from "react-router-dom";
import {useState, useEffect} from 'react'
import Sidebar from '../Components/Sidebar'
import {AWSSidebarData} from './AWSSidebarData.js';
import AWSSubMenu from './AWSSubMenu.js'

const AWSNavbar = (props) => {

    const location = useLocation()
    const [GCPAccessToken, setGCPAccessToken] = useState()

    const navigate = useNavigate()
    const goHome = () => {
        console.log('hello')
        navigate('/', { state: 
            { GCPAccessToken: location.state.GCPAccessToken,
              AWSAccessToken: location.state.AWSAccessToken,
              type: 'Projects',
              key: Date.now()
            }
          })
    }
    
    useEffect(() => {

        if(props.GCPAccessToken || location.state.GCPAccessToken){
            setGCPAccessToken(props.GCPAccessToken || location.state.GCPAccessToken)
        }
    }, [props.GCPAccessToken])
    
    

    return (  
        <div>
            <nav className="Navbar">
                <Sidebar SidebarData={AWSSidebarData} SubMenu={AWSSubMenu}></Sidebar>
                <div className="NavbarTitle">
                    <h3 style={{cursor:'pointer'}} onClick={goHome}><img className="NavbarLogo" src={droplets} alt="Droplets"/> Raindrop</h3>
                </div>
                {
                    GCPAccessToken ?
                    (null) :
                    (<div className="NavbarGoogleLogin">
                        <GoogleLogin setGCPAccessToken={props.setGCPAccessToken}></GoogleLogin>
                    </div>
                    ) 
                }
                
            </nav>
            
        </div>
        
    );
}
 
export default AWSNavbar;