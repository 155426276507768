
import {useState, useEffect} from 'react'
import {useNavigate, useLocation} from "react-router-dom";


const AWSBlobs = (props) => {
    
    
    const navigate = useNavigate()
    const location = useLocation()
    const [blobs, setBlobs] = useState(null)
    const [AWSAccessToken, setAWSAcessToken] = useState(null)
    const [GCPAccessToken, setGCPAcessToken] = useState(null)
    const [bucketId, setBucketId] = useState(null)

    useEffect(() => {
        setAWSAcessToken(props.AWSAcessToken)
        setGCPAcessToken(props.GCPAcessToken)
        setBucketId(props.bucketId)
    }, [])
    
    useEffect(() => {
        setBlobs(props.data)  
    }, [props.data])

    

    const accessBlob = (blob) => {
        if(blob.match("^.*/$")){
            navigate(`/aws/s3/${bucketId}/${blob}`, { state: 
                {   AWSAccessToken: props.AWSAccessToken,
                    GCPAccessToken: props.GCPAccessToken,
                    bucketId: props.bucketId,
                    type: 'Blobs',
                    blob: blob,
                    key: Date.now()
                }
              })
        }
        
    }

    const targetBlob = (e, bucket, blob, index) => {
        e.preventDefault();
        //need props because AWSUsers is rendering
        props.blobTargetFunction(e.currentTarget,  bucket, blob, index)
    }



    return ( 
        <div className="content-list">
            {
                blobs
                ? blobs.map((p, index) => (
                    <div className="content-preview" key={index} onClick={(e) => targetBlob(e, props.bucketId, p.Prefix, index)} onDoubleClick={() => accessBlob(p.Prefix)}>  
                            <div className="row">
                                <div className="column" >
                                    <p>{p.name}</p>
                                </div>
                                <div className="column" >
                                    <p>{p.Size}</p>
                                </div>
                                
                                <div className="column" >
                                    <p>{p.LastModified}</p>
                                </div>
                            </div>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default AWSBlobs;