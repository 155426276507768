import './GoogleFileLinkForm.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";

const displayForm = (isPoppedup) => {
    document.getElementById('fileLinkForm').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('fileLinkForm').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const GoogleFileLinkForm = (props) => {

    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [project, setProject] = useState(null)
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    const [urlLink, seturlLink] = useState(null)
    const [target, setTarget] = useState(null)
    useOutsideAlerter(modalRef, isPoppedup, props.closeModal);

    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProject(props.project)
    }, [])

    useEffect(() => {
        if(props.target.length == 1){
            setTarget(props.target[0])
        }
    }, [props.target])


    useEffect(() => {
        
        if(props.showModal){
           displayForm(isPoppedup)
        }
    }, [props.showModal])

    const getLink = async (e) => {
        e.preventDefault();
        const time = document.getElementById('timeInput').value
        if(time){
            console.log({ params: {accessToken: GCPAccessToken, project: project, bucket: target['bucket'], blob: target['blob'], time: time}})
            await axios.get(`/api/gcloud/storage/getSignedURL`, { params: {accessToken: GCPAccessToken, project: project, bucket: target['bucket'], blob: target['blob'], time: time}}).then(res => {
                seturlLink(res.data)
            })
        }
        
    }

    return ( 
        
        <div>
            <div id="fileLinkForm" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>Generate GCS File Link</h3>
                    <p>Please enter a time duration:</p>
                    <div className="clearfix">
                        <div>
                            <input id="timeInput" type="text" className="browser-default"/>
                        </div>
                        <button type="submit" className="signupbtn" onClick={getLink}>Create URL</button>
                    </div>
                    <hr/>
                        <div className="display">
                        {   urlLink ?
                                
                                <p>{urlLink}</p>
                            :   <p>URL will be displayed here.</p>

                        }
                        </div>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default GoogleFileLinkForm;