import './GoogleProjects.css'
import { useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";

const ProjectsList = (props) => {
    
    const navigate = useNavigate()
    const [projects, setProjects] = useState(null)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)

 
    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)

    }, [])


    useEffect(() => {
        setProjects(props.data)
    }, [props.data])



    const routeChange = (projectId) => {
        navigate(`/gcp/storage/${projectId}`, { state: 
            {   AWSAccessToken: AWSAccessToken,
                GCPAccessToken: GCPAccessToken,
                projectId: projectId,
                type: 'Buckets',
                key: Date.now()
            }
          })
    }

    return ( 
        <div className="content-list">
            {
                projects
                ? projects.map((p, index) => (
                    <div className="content-preview" key={index}>
                        <a onDoubleClick={() => routeChange(p.projectId)}>
                            <div className="row">
                                <div className="column" >
                                    <p>{p.name}</p>
                                </div>
                                <div className="column" >
                                    <p>{p.projectId}</p>
                                </div>
                                
                                <div className="column" >
                                    <p>{p.projectNumber}</p>
                                </div>
                            
                            </div>
                        </a>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default ProjectsList;