//External imports
import { useState, useEffect} from 'react'
import {useNavigate, useLocation} from "react-router-dom";
import AWSLoginButton from "./AWSLoginButton";
import axios from 'axios';
import AWSLoginPopup from './AWSLoginPopup';

const AWSLogin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : null
  )
  const [clientName, setClientName] = useState(null)
  const [AccessKey, setAccessKey] = useState(null)
  const [SecretKey, setSecretKey] = useState(null)
  const [showModal, setShowModal] = useState(false)


useEffect(() => {
  
if(accessToken && clientName){
 

    if(location.pathname === '/login' || location.pathname === '/'){
      navigate('/aws/s3', { state: 
        { GCPaccessToken: location.state && location.state.GCPAccessToken,
          AWSAccessToken: accessToken,
          bucketId: 'qualia-data-test',
          blob: 'customer/' + clientName,
          type: 'Blobs',
          key: Date.now()
        }
      })
    }else{
      props.setAWSAccessToken(accessToken)
    }
  }
  else if(accessToken){
    if(location.pathname === '/login' || location.pathname === '/'){
      navigate('/aws/s3', { state: 
        { GCPaccessToken: location.state && location.state.GCPAccessToken,
          AWSAccessToken: accessToken,
          type: 'Buckets',
          key: Date.now()
        }
      })
    }else{
      props.setAWSAccessToken(accessToken)
    }
  }
}, [accessToken])

useEffect(() => {
  const login = async () => {
    console.log('afsdffasd')
    await axios.post('/api/aws/login', null, {
      headers: {
      'AccessKey': AccessKey,
      'SecretKey': SecretKey,
       'content-type': 'multipart/form-data' 
      }}).then((res) => {
        const response = JSON.parse(res.data)
        console.log(response.accessToken)
        setAccessToken(response.accessToken)
        if(response.clientName){
          setClientName(response.clientName)
        }
      })
  }
  if(AccessKey && SecretKey){
    login()
  }
}, [AccessKey || SecretKey])

      


const AWSSignIn = (e) => {
  e.preventDefault();
  const AccessKey = document.getElementById('AccessKey').value
  const SecretKey = document.getElementById('SecretKey').value
  setAccessKey(AccessKey)
  setSecretKey(SecretKey)
}

const displaySignInModal = () => {
  setShowModal(true)
} 

const closeSignInModal = () => {
    setShowModal(false)
}


  return (
  <div className='AwsLogin'>
          <div> 
            { location.pathname === '/login' || location.pathname === '/' ?
              <>
              <label for="AccessKey"><b>AccessKey</b></label>
              <br></br>
              <input type="text" id="AccessKey" className="browser-default"/>
              <br></br>
              <label for="SecretKey"><b>SecretKey</b></label>
              <br></br>
              <input type="text" id="SecretKey" className="browser-default"/>
              <br></br>
              <AWSLoginButton AWSSignIn={AWSSignIn} ></AWSLoginButton>
              </>
              : 
              <>
              <AWSLoginPopup AWSSignIn={AWSSignIn} showModal={showModal} closeModal={closeSignInModal} ></AWSLoginPopup>
              <AWSLoginButton AWSSignIn={displaySignInModal} ></AWSLoginButton>
              </>
              
            }
            
            
          </div>
  </div>
  )
}

export default AWSLogin;