import droplets from '../Images/droplets.png'
import '../Components/Navbar.css'
import { useNavigate, useLocation } from "react-router-dom";
import {useState, useEffect} from 'react'
import Sidebar from '../Components/Sidebar'
import AWSLogin from '../AWS/AWSLogin'
import {GoogleSidebarData} from './GoogleSidebarData.js';
import GoogleSubMenu from './GoogleSubMenu'


const GCPNavbar = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [AWSAccessToken, setAWSAccessToken] = useState()


    
    useEffect(() => {

        if(props.AWSAccessToken || location.state.AWSAccessToken){
            setAWSAccessToken(props.AWSAccessToken || location.state.AWSAccessToken)
        }
    }, [props.AWSAccessToken])

    const goHome = () => {
        navigate('/', { state: 
            { GCPAccessToken: location.state.GCPAccessToken,
              AWSAccessToken: location.state.AWSAccessToken,
            }
          })
    }


    
    

    return (  
        <div>
            <nav className="Navbar">
                <Sidebar SidebarData={GoogleSidebarData} SubMenu={GoogleSubMenu}></Sidebar>
                <div className="NavbarTitle">
                    <h3 style={{cursor:'pointer'}} onClick={goHome}><img className="NavbarLogo" src={droplets} alt="Droplets"/> Raindrop</h3>
                </div>
                {
                    AWSAccessToken ?
                    (null) :
                    (<div className="NavbarAWSLogin">
                        <AWSLogin setAWSAccessToken={props.setAWSAccessToken}></AWSLogin>
                    </div>
                    ) 
                }
                
            </nav>
            
        </div>
        
    );
}
 
export default GCPNavbar;