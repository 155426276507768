import './AWSTransferS3toGCS.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";

const displayForm = (isPoppedup) => {
    document.getElementById('fileLinkFormS3toGCS').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('fileLinkFormS3toGCS').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const AWSTransferS3toGCS = (props) => {


    const location = useLocation()
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [bucket, setBucket] = useState(null)
    const [blob, setBlob] = useState(null)
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    useOutsideAlerter(modalRef, isPoppedup, props.closeModalS3toGCS);

  
    
    useEffect(() => {
        setBucket(location.state.bucket)
        setBlob(location.state.blob)
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(location.state.GCPAccessToken)
    }, [location.state])

    useEffect(() => {
        if(props.showModalS3toGCS){
           displayForm(isPoppedup)
        }
    }, [props.showModalS3toGCS])

    useEffect(() => {
        if(props.GCPAccessToken){
            setGCPAccessToken(props.GCPAccessToken)
        }
    }, [props.GCPAccessToken])

    const uploadBQTable = async (e) => {
        e.preventDefault();
        const tableID = document.getElementById('tableIDInputS3toGCS').value
        const GCSPath = document.getElementById('GCSPathInputS3toGCS').value
        if(tableID){
           await axios.post('/api/aws/s3/upload/storage', null, {
                headers: {
                    "AWSAccessToken": AWSAccessToken, 
                    "GCPAccessToken": JSON.stringify(GCPAccessToken), 
                    "bucket": bucket, 
                    "blob": blob, 
                    "tableID": tableID, 
                    "GCSPath": GCSPath,
                    'content-type': 'text/json'
                }})
        }
        
    }

    return ( 
        
        <div>
            <div id="fileLinkFormS3toGCS" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>File Transfer S3 to GCS</h3>
                    
                    <div className="clearfix">
                        <p>Enter a S3 File Path</p>
                        <div>
                            <input id="tableIDInputS3toGCS" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a GCS Path</p>
                        <div>
                            <input id="GCSPathInputS3toGCS" type="text" className="browser-default"/>
                        </div>
                        <button type="submit" className="signupbtn" onClick={uploadBQTable} style={{backgroundColor:"#6d44ff"}}>Upload to GCS</button>
                    </div>
                    <hr/>
                        <div className="display">

                        </div>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default AWSTransferS3toGCS;