import './GoogleTransferBQtoS3.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";

const displayForm = (isPoppedup) => {
    document.getElementById('fileLinkForm').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('fileLinkForm').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const GoogleTransferBQtoS3 = (props) => {

    const location = useLocation()
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [bucketName, setBucketName] = useState(null)
    const [fileLocation, setFileLocation] = useState(null)
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    useOutsideAlerter(modalRef, isPoppedup, props.closeModal);


    
    useEffect(() => {
        setBucketName(location.state.bucketName)
        setFileLocation(location.state.blob)
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(location.state.GCPAccessToken)
    }, [location.state])

    useEffect(() => {
        if(props.showModal){
           displayForm(isPoppedup)
        }
    }, [props.showModal])

    useEffect(() => {
        if(props.GCPAccessToken){
            setGCPAccessToken(props.GCPAccessToken)
        }
    }, [props.GCPAccessToken])

    const uploadBQTable = async (e) => {
        e.preventDefault();
        const S3File = document.getElementById('S3FileInputBQtoS3').value
        const GCSPath = document.getElementById('GCSPathInputBQtoS3').value
        const BQTable = document.getElementById('BQTableInputBQtoS3').value
        const Delimeter = document.getElementById('Delimeter').value
        const destinationFormat = document.getElementById('destinationFormat').value
        const compressionType = document.getElementById('compressionType').value
        
        if(S3File && GCSPath && BQTable){

          
            await axios.post('/api/gcloud/bigquery/upload/s3', null, {

                headers: {
                    "AWSAccessToken": props.AWSAccessToken, 
                    "GCPAccessToken": JSON.stringify(GCPAccessToken),
                    "S3File": S3File,
                    "GCSPath": GCSPath,
                    "BQTable": BQTable,
                    "Delimeter": Delimeter,
                    "compressionType": compressionType,
                    "destinationFormat": destinationFormat,
                    'content-type': 'text/json'
                }}).then((res) => {
                  console.log('success')
                })
        }
        
    }

    return ( 
        
        <div>
            <div id="fileLinkForm" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>File Transfer BQ to S3</h3>
                    
                    <div className="clearfix">
                        <p>Enter a S3 csv File Path</p>
                        <div>
                            <input id="S3FileInputBQtoS3" type="text" className="browser-default"/>
                        </div>
                        <p>Enter GCS Path</p>
                        <div>
                            <input id="GCSPathInputBQtoS3" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a BQ Table ID</p>
                        <div>
                            <input id="BQTableInputBQtoS3" type="text" className="browser-default"/>
                        </div>
                        <p>Delimeter (Optional)</p>
                        <div>
                            <input id="Delimeter" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a Destination Format (Optional)</p>
                        <div>
                            <input id="destinationFormat" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a Compression Type (Optional)</p>
                        <div>
                            <input id="compressionType" type="text" className="browser-default"/>
                        </div>
                        <button type="submit" className="signupbtn" onClick={uploadBQTable} style={{backgroundColor:"#6d44ff"}}>Upload to S3</button>
                    
                    </div>
                    <hr/>
                        <div className="display">

                        </div>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default GoogleTransferBQtoS3;