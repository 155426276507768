
import { useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";


const GoogleBuckets = (props) => {
    
    const navigate = useNavigate()
    const [buckets, setBuckets] = useState(null)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [projectId, setProjectId] = useState(null)

    
    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProjectId(props.projectId)
    }, [])
  
    useEffect(() => {
        
        setBuckets(JSON.parse(props.data))
    }, [props.data])

    

    const routeChange = (bucketId) => {
        navigate(`/gcp/storage/${projectId}/${bucketId}/${''}`, { state: 
            {   AWSAccessToken: AWSAccessToken,
                GCPAccessToken: GCPAccessToken,
                projectId: projectId,
                bucketId: bucketId,
                type: 'Blobs',
                blob: '',
                key: Date.now()
            }
          })
    }

    return ( 
        <div className="content-list">
            {
                buckets
                ? buckets.map((p, index) => (
                    <div className="content-preview" key={index}>
                        <a onDoubleClick={() => routeChange(p.bucketId)}>
                            <div className="row">        
                                <div className="column" >
                                    <p>{p.name}</p>
                                </div>
                                <div className="column" >
                                    <p>{p.bucketId}</p>
                                </div>
                                
                                <div className="column" >
                                    <p>{p.storageClass}</p>
                                </div>
                            
                            </div>
                        </a>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default GoogleBuckets;