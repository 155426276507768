
import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";


const GoogleBlobs = (props) => {
    
    const navigate = useNavigate()
    const [blobs, setBlobs] = useState(null)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [bucketId, setBucketId] = useState(null)
    
    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProjectId(props.projectId)
        setBucketId(props.bucketId)
    }, [])

    useEffect(() => {
        console.log(props.data)
        setBlobs(props.data)
    }, [props.data])



    const targetBlob = (e, tableId, index) => {
        e.preventDefault();
        props.blobTargetFunction(e.currentTarget, tableId, index)
    }

    return ( 
        <div className="content-list">
            {
                blobs
                ? blobs.map((p, index) => (
                    <div className="content-preview" key={index} onClick={(e) => targetBlob(e, p._properties.id, index)}>  
                            <div className="row">
                                <div className="column" >
                                    <p>{p._properties.tableReference.tableId}</p>
                                </div>
                                <div className="column" >
                                    <p>{p._properties.creationTime}</p>
                                </div>
                            </div>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default GoogleBlobs;