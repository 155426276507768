
import { useState, useEffect } from 'react'
import '../../Components/UserToolbar.css'
import axios from 'axios'
import FormData from 'form-data'

const GoogleUserToolbar = (props) => {

    const [type, setType] = useState(null)
    const [content, setContent] = useState(null)
    const [target, setTarget] = useState(null)


    useEffect(() => {
        setType(props.type)
    }, [props.type])

    useEffect(() => {
        setTarget(props.target)
    }, [props.target])
    

    useEffect(() => {
        if(content){
            const data = new FormData();
            Array.from(content).forEach(file => {
                data.append('file', file)
            })
            
            console.log(props)
            axios.post('/api/gcloud/storage/upload/file', data, {
                headers: {
                 'accessToken': JSON.stringify(props.GCPAccessToken),
                 'project': props.project,
                 'bucket': props.bucket,
                 'blob': props.blob,
                 'content-type': 'multipart/form-data' 
                }})
        }
    }, [content])

    

    const uploadFilesButtonPress = () => {
        document.getElementById("inputFiles").value = ''
        document.getElementById("inputFiles").addEventListener("onChange", event => {uploadFiles(event)}, {once: true})
        document.getElementById("inputFiles").click()
    }


    const uploadFiles = (e) => {
        setContent(e.target.files)
    }

    // const getSignedURL = () => {
    //     props.urlButtonTrigger()
    // }

    const uploadToS3 = () => {
        props.uploadToS3ButtonTrigger()
    }

    const uploadToS3Group = () => {
        props.uploadToS3GroupButtonTrigger()
    }

    return (  
        
        <div className="toolbar">
            {(() => {
                    if (type === 'Blobs' && target) {
                    return (
                        <div>
                            <input id="inputFiles" className="input" type="file" onChange={uploadFiles} multiple hidden/>
                            <button id="buttonFiles" className="button" onClick={uploadFilesButtonPress}>Upload Local Files</button>
                            {/* <button id="buttonFiles" className="button" >Delete Files</button> */}
                            {
                                <button id="buttonS3Upload" className="button" onClick={uploadToS3Group}>Upload Group to S3</button>
      
                            }
                            
                            <div className="clear"></div>
                        </div>
                    )
                    } else if (type === 'Blobs') {
                    return (
                        <div>
                            <input id="inputFiles" className="input" type="file" onChange={uploadFiles} multiple hidden/>
                            <button id="buttonFiles" className="button" onClick={uploadFilesButtonPress}>Upload Local Files</button>
                            <div className="clear"></div>
                        </div>
                    )
                    } else {
                    return (
                        null
                    )
                    }
                })()}
            
        </div>
    );
}
 
export default GoogleUserToolbar;