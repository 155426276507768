const GoogleBlobsFields = () => {
    return ( 
        <div className="row">
            <div className="column" >
                <p><b>TableId</b></p>
            </div>
            <div className="column" >
                <p><b>Creation Time</b></p>
            </div>
        </div>
     );
}
 
export default GoogleBlobsFields;