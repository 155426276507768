
import { useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";


const AWSBuckets = (props) => {
    
    const navigate = useNavigate()
    const [buckets, setBuckets] = useState(null)
    const [AWSAccessToken, setAWSAcessToken] = useState(null)
    const [GCPAccessToken, setGCPAcessToken] = useState(null)
  
    
    useEffect(() => {
        setAWSAcessToken(props.AWSAccessToken)
        setGCPAcessToken(props.GCPAccessToken)

    }, [])

    useEffect(() => {
        setBuckets(props.data)
    }, [props.data])

  
    const routeChange = (bucketId) => {

        navigate(`/aws/s3/${bucketId}/${''}`, { state: 
            {   AWSAccessToken: AWSAccessToken,
                GCPAccessToken: GCPAccessToken,
                bucketId: bucketId,
                blob: '',
                type: 'Blobs',
                key: Date.now()
            }
          })
    }

    return ( 
        <div className="content-list">
            {
                buckets
                ? buckets.map((p, index) => (
                    <div className="content-preview" key={index}>
                        <a onDoubleClick={() => routeChange(p.Name)}>
                            <div className="row">        
                                <div className="column" >
                                    <p>{p.Name || p.name}</p>
                                </div>
                                <div className="column" >
                                    <p>{p.CreationDate}</p>
                                </div>
                                
                            
                            </div>
                        </a>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default AWSBuckets;