

const AWSLoginButton = (props) => {
    return ( 
        <div className="col s12 m6 offset-m3 center-align">
           
            <button className="oauth-container btn darken-4 white black-text"  onClick={props.AWSSignIn} style={{textTransform:"none"}}>
                <div className="left">
                <img style={{marginTop:"7px", marginRight: "8px"}} alt="Google sign-in" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVR4nO1SPWgUURAekxSiaPAnhiAY727eXQx38zaeSjh25mEMRm2EQGwDioWFVUDRwhNBUQxKQHL7RIhYaQRtVJD0giJILESFgJ02iqgQRHDk7d1ZaBAi2ogDj3377XzffPOxAP/rj1RXubzMRLLHRDyKUdwbsHwxJozijnDPlgfb85FsbfYb66INpXhV4OWs7Au8jX3bu78LIslVY+UKWp4wVt7liIeM5cnwngoQH0PiL4VCZYVzrg2tfMiSK6KVWUN8I/QhsV/QrSE+iySX8iXZHQj1gXwXSR6ayA1iyfUb4peZ4kCnsfI1T5z5SSRHbpchuW+IHwQRJJnq7nZLg5OwdhAw1h00lk+akhxHy+P14XIBLX9C4lt5cjE08zGW5xvAErRyJgg2oriNJNXwLJQqBSSeCceUhJtmMsWBTrRyNGj09OxYAz1l7kqtF2PKRmKQ+EkgNQT3o5W3hmSsMWwOiV/ByEhrb2//aoxiF3JNY7A8H7BGbjKGlp8a4ulcxBVDcjEVjOIOtHzP9MmmxoATaOVI+heU3drQj1aeI/EjQzIMf6X0MgxpFVp+m+9hp3q4qQnMagIzAdisHu6oh1G9BssXLViFFk1AdBLWqYe55pR29eDVw3v1cF1rcEBrYLUKbQuK1GC91mCvepjQBF6oh1Nag22awPkf18+oh5om8EY9qCbwWT281gSeaQKPgwP18DH9VsfPBU7KTWBYJ2Dlr9bYogkcVg+nNYGp9HgYVw+HUvfT0LrYeODfrG+86N+fe751DQAAAABJRU5ErkJggg=="/>
                </div>
                Login with AWS
            </button>
    
        </div>
     );
}
 
export default AWSLoginButton;