import './GoogleUploadToS3Groups.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";
import loading_Gif from '../../Components/resources/loading.gif'

const displayForm = (isPoppedup) => {
    document.getElementById('uploadToS3FormGroup').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('uploadToS3FormGroup').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const GoogleUploadToS3Group = (props) => {

    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [project, setProject] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)   
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)

    useOutsideAlerter(modalRef, isPoppedup, props.closeModal);

    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProject(props.project)
        setError(null)
    }, [])



    useEffect(() => {
        
        if(props.showModal){
           displayForm(isPoppedup)
        }
    }, [props.showModal])

    function strmatch(str, pattern, n, m)
    {

        // empty pattern can only match with
        // empty string
        if (m == 0)
            return (n == 0);
    
        // lookup table for storing results of
        // subproblems
        // initialize lookup table to false
        let lookup = new Array(n + 1).fill(false).map(()=>new Array(m + 1).fill(false));
    
        // empty pattern can match with empty string
        lookup[0][0] = true;
    
        // Only '*' can match with empty string
        for (let j = 1; j <= m; j++)
            if (pattern[j - 1] == '*')
                lookup[0][j] = lookup[0][j - 1];
    
        // fill the table in bottom-up fashion
        for (let i = 1; i <= n; i++) {
            for (let j = 1; j <= m; j++) {
                // Two cases if we see a '*'
                // a) We ignore ‘*’ character and move
                //    to next  character in the pattern,
                //     i.e., ‘*’ indicates an empty sequence.
                // b) '*' character matches with ith
                //     character in input
                if (pattern[j - 1] == '*')
                    lookup[i][j]
                        = lookup[i][j - 1] || lookup[i - 1][j];
    
                // Current characters are considered as
                // matching in two cases
                // (a) current character of pattern is '?'
                // (b) characters actually match
                else if (pattern[j - 1] == '?'
                        || str[i - 1] == pattern[j - 1])
                    lookup[i][j] = lookup[i - 1][j - 1];
    
                // If characters don't match
                else
                    lookup[i][j] = false;
            }
        }
    
        return lookup[n][m];
    }

    function getGroupTargets(s3MatchingGroup) {
        let groupTargets = []
        const blobs = JSON.parse(props.blobs)
        blobs.forEach(blob => {
            if(strmatch(blob.name, s3MatchingGroup, blob.name.length, s3MatchingGroup.length) && blob.isFile){
                groupTargets.push(blob)
            }
        })
        return groupTargets
    }

    const uploadToS3 = async (e) => {
        e.preventDefault();
        const s3File = document.getElementById('s3FileInputGroup').value
        const s3MatchingGroup = document.getElementById('s3MatchingGroup').value
        const groupTargets = getGroupTargets(s3MatchingGroup)
        const blobs = JSON.stringify(groupTargets)
        if(s3File){
            setLoading(true)
            axios.post('/api/gcloud/storage/uploadGroup/s3', null, {
                
                headers: {
                    "AWSAccessToken": props.AWSAccessToken, 
                    "GCPAccessToken": JSON.stringify(props.GCPAccessToken),
                    "GCSProject": props.project, 
                    "GCSBucket": props.bucket,
                    "GCSBlobs": blobs,
                    "S3File": s3File,
                    'content-type': 'text/json'
                }}
                ).then((res) => {
                    setError('Successfully uploaded to S3')
                }
                ).catch((err) => {
                    setError('Error uploading to S3, check if you have AWS credentials')
                }
                )
            setLoading(false)
        }else{
            setError('Please enter a S3 bucket')
        }
        
    }

    return ( 
        
        <div>
            <div id="uploadToS3FormGroup" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>Upload Multiple GCS to S3</h3>
                    {
                        Error ? 
                        (<>
                        {/* <button id="buttonFiles" className="button" onClick={getSignedURL}>Create Signed URL</button>  */}
                        <div style={{color:"red"}}>{error}</div>                      
                        </>
                        ) :
                        (null)
                    }
                    {
                       
                        loading==true ?
                        (
                            <p>Loading...</p>
                        ) :
                        (null)
                    }
                    <div className="clearfix">
                        <p>Please enter a group of files</p>
                        <div>
                            <input id="s3MatchingGroup" type="text" className="browser-default"/>
                        </div>
                    </div>
                    <div className="clearfix">
                        <p>Please enter a S3 bucket</p>
                        <div>
                            <input id="s3FileInputGroup" type="text" className="browser-default"/>
                        </div>
                    </div>
                    <button type="submit" className="signupbtn" onClick={uploadToS3}>Upload to S3</button>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default GoogleUploadToS3Group;