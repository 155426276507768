import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
  
const SidebarLink = styled.nav`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  background: white;
  
  &:hover {
    background: gray;
    border-left: 4px solid blue;
    cursor: pointer;
  }
`;
  
const SidebarLabel = styled.span`
  margin-left: 16px;
`;
  
const DropdownLink = styled.nav`
  background: black;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  
  &:hover {
    background: green;
    cursor: pointer;
  }
`;


  
const SubMenu = ({ item }) => {
  const location = useLocation();
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const navigate = useNavigate()
  

  const subMenuCliked = (path) => {
    console.log('hello')
    navigate(path, { state: 
      { GCPAccessToken: location.state.GCPAccessToken,
        AWSAccessToken: location.state.AWSAccessToken,
        type: 'Buckets',
        key: Date.now()
      }
    })
  }
    

  
  return (
    <>
      <SidebarLink
      // onClick={item.subNav && showSubnav}
      onClick={() => subMenuCliked(item.path)}
      >
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {/* {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })} */}
    </>
  );
};
  
export default SubMenu;