import './AWSTransferS3toGCS.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";

const displayForm = (isPoppedup) => {
    document.getElementById('fileLinkFormS3toBQ').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('fileLinkFormS3toBQ').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const AWSTransferS3toBQ = (props) => {

 
    const location = useLocation()
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [bucket, setBucket] = useState(null)
    const [blob, setBlob] = useState(null)
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    useOutsideAlerter(modalRef, isPoppedup, props.closeModalS3toBQ);

  
    
    useEffect(() => {
        setBucket(location.state.bucket)
        setBlob(location.state.blob)
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(location.state.GCPAccessToken)
    }, [location.state])

    useEffect(() => {
        if(props.showModalS3toBQ){
           displayForm(isPoppedup)
        }
    }, [props.showModalS3toBQ])

    useEffect(() => {
        if(props.GCPAccessToken){
            setGCPAccessToken(props.GCPAccessToken)
        }
    }, [props.GCPAccessToken])

    const uploadBQTable = async (e) => {
        e.preventDefault();
        const S3File = document.getElementById('S3FileInputS3toBQ').value
        const GCSPath = document.getElementById('GCSPathInputS3toBQ').value
        const BQTable = document.getElementById('BQTableInputS3toBQ').value
        const CSVDelimeter = ','
        if(document.getElementById('CSVDelimeter')){
            CSVDelimeter = document.getElementById('CSVDelimeter').value
        }
        

        if(S3File && GCSPath && BQTable){
            
           await axios.post('/api/aws/s3/upload/bq', null, {
                headers: {
                    "AWSAccessToken": AWSAccessToken, 
                    "GCPAccessToken": JSON.stringify(GCPAccessToken), 
                    "S3File": S3File, 
                    "GCSPath": GCSPath,
                    "BQTable": BQTable,
                    "CSVDelimeter": CSVDelimeter,
                    'content-type': 'text/json'
                }})
        }
        
    }

    return ( 
        
        <div>
            <div id="fileLinkFormS3toBQ" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>File Transfer S3 to BQ</h3>
                    
                    <div className="clearfix">
                        <p>Enter a S3 csv File Path</p>
                        <div>
                            <input id="S3FileInputS3toBQ" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a GCS Path</p>
                        <div>
                            <input id="GCSPathInputS3toBQ" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a BQ Table ID</p>
                        <div>
                            <input id="BQTableInputS3toBQ" type="text" className="browser-default"/>
                        </div>
                        <p>Enter a Delimeter (Optional)</p>
                        <div>
                            <input id="Delimeter" type="text" className="browser-default"/>
                        </div>
                        <button type="submit" className="signupbtn" onClick={uploadBQTable} style={{backgroundColor:"#6d44ff"}}>Upload to BQ</button>
                    </div>
                    <hr/>
                        <div className="display">

                        </div>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default AWSTransferS3toBQ;