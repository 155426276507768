
import './App.css';
import Login from './Login';
import GoogleStorage from './GCP/GCS/GoogleStorage'
import GoogleBigQuery from './GCP/BigQuery/GoogleBigQuery';
import AWSS3 from './AWS/S3/AWSS3'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import axios from "axios";


if(process.env.NODE_ENV == 'production'){
  axios.defaults.baseURL = 'https://sftp-server-dot-adstra-tools.uc.r.appspot.com'
}else{
  axios.defaults.baseURL = 'http://localhost:8000'
}


function App() {
  return (

      <div className="App">
        <Router>
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route path="login" element={<Login />} />

          <Route path="/gcp/storage" element={<GoogleStorage />} />
          <Route path="/gcp/storage/:projectId" element={<GoogleStorage />} />
          <Route path="/gcp/storage/:projectId/:bucketId/*" element={<GoogleStorage />} />

          <Route path="/gcp/bigquery" element={<GoogleBigQuery/>} />
          <Route path="/gcp/bigquery/:projectId" element={<GoogleBigQuery/>} />
          <Route path="/gcp/bigquery/:projectId/:datasetId" element={<GoogleBigQuery/>} />

          <Route path="/aws/s3" element={<AWSS3 />} />
          <Route path="/aws/s3/:bucketId/*" element={<AWSS3 />} />
        </Routes>
      </Router>
      </div>

  );
}

export default App;
