import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import "./Sidebar.css"
import { useEffect } from "react";

  

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
  
  
const Sidebar = ({SidebarData, SubMenu}) => {
  const [sidebar, setSidebar] = useState(false);
  
  const showSidebar = () => setSidebar(true);
  const hideSidebar = () => setSidebar(false);

  return (
    <>
          <div className="NavIcon">
            <FaIcons.FaBars onMouseEnter={showSidebar}color="black"/>
          </div>
        <SidebarNav sidebar={sidebar} onMouseEnter={showSidebar} onMouseLeave={hideSidebar}>
          <div className="SidebarWrap">
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </div>
        </SidebarNav>
    </>
  );
};
  
export default Sidebar;