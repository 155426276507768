import { useState, useEffect, useRef} from 'react'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import GoogleProjects from './GoogleProjects';
import GoogleProjectsFields from './GoogleProjectsFields';
import GoogleBuckets from './GoogleBuckets';
import GoogleBucketsFields from './GoogleBucketsFields';
import GoogleBlobs from './GoogleBlobs';
import GoogleBlobsFields from './GoogleBlobsFields';
import GoogleStorageToolbar from './GoogleStorageToolbar';
import GoogleFileLinkForm from './GoogleFileLinkForm';
import GoogleUploadToS3Form from './GoogleUploadToS3Form';
import GoogleUploadToS3FormGroups from './GoogleUploadToS3Groups';
import GCPNavbar from '../GCPNavbar'
import '../../Components/Columns.css';
import '../../Components/User.css'

const iterativeFunction = function (arr, x) {
  

    let start=0, end=arr.length-1;

    
         
    // Iterate while start not meets end
    while (start<=end){
 
        // Find the mid index
        let mid=Math.floor((start + end)/2);
  
        // If element is present at mid, return True
        if (arr[mid]["index"]===x["index"]){
            arr.splice(mid, 1);
            return true;
        } 
 
        // Else look in left or right half accordingly
        else if (arr[mid]["index"] < x["index"])
             start = mid + 1;
        else
             end = mid - 1;
    }
    arr.splice(start, 0, x)
    return false
}

//Load variables/ Loading ... -> run useEffect -> set data -> render entire page
const GoogleStorage = () => {
    const location = useLocation();
    const [bucket, setBucket] = useState(null)
    const [projects, setProjects] = useState(null)
    const [buckets, setBuckets] = useState(null)
    const [blobs, setBlobs] = useState(null)
    const [targets, setTargets] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showUploadToS3Modal, setShowUploadToS3Modal] = useState(false)
    const [showUploadToS3ModalGroup, setShowUploadToS3ModalGroup] = useState(false)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    


    useEffect(() => {
        if(location.state.type && ((location.state.type === 'Projects' || location.state.type === 'Buckets') || (location.state.type === 'Blobs' && location.state.blob != null))){
            const getProjects = async () => {
                await axios.get('/api/gcloud', { params: { accessToken: location.state.GCPAccessToken}}).then(res => {
                    setProjects(res.data)
                    
                })
            }
            const getBuckets = async () => {
                await axios.get(`/api/gcloud/storage/${location.state.projectId}`, { params: { accessToken: location.state.GCPAccessToken}}).then(res => {
                    setBuckets(res.data)
                })
            }
            const getBlobs = async () => {
                await axios.get(`/api/gcloud/storage/${location.state.projectId}/${location.state.bucketId}/content`, { params: { accessToken: location.state.GCPAccessToken, blob: location.state.blob}}).then(res => {
                    setBlobs(res.data.blobs)
                    setBucket(res.data.bucket)
                })
            }
        
            if(location.state.type === 'Projects'){
                getProjects().catch(console.error);
            } else if(location.state.type === 'Buckets'){
                getBuckets().catch(console.error);
            } else if(location.state.type === 'Blobs' && location.state.blob != null){
                getBlobs().catch(console.error);
            }
        }

    }, [location.state.key])


    const blobSelected = (e, project, bucket, blob, index) => {
        
        if(iterativeFunction(targets, {project, bucket, blob, index})){
            e.style.backgroundColor = 'transparent';
        }else{
            e.style.backgroundColor = 'rgba(127, 39, 245, 0.25)';
        }    
        
        setTargets([...targets])
    }

    const displaySignedURLModal = () => {
            setShowModal(true)
    }

    const closeSignedURLModal = () => {
            setShowModal(false)
    }

    const displayUploadToS3Modal = () => {
        setShowUploadToS3Modal(true)
    }

    const closeUploadToS3Modal = () => {
        setShowUploadToS3Modal(false)   
    }

    const displayUploadToS3GroupModal = () => {
        setShowUploadToS3ModalGroup(true)
    }

    const closeUploadToS3GroupModal = () => {
        setShowUploadToS3ModalGroup(false)   
    }

    

    return (
        <div>
            <GCPNavbar AWSAccessToken={AWSAccessToken} setAWSAccessToken={setAWSAccessToken}/>
            <div className="GoogleStorage">
            <GoogleStorageToolbar type={location.state.type} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} project={location.state.projectId} bucket={location.state.bucketId} blob={location.state.blob} target={targets} urlButtonTrigger={displaySignedURLModal} uploadToS3ButtonTrigger={displayUploadToS3Modal} uploadToS3GroupButtonTrigger={displayUploadToS3GroupModal}></GoogleStorageToolbar>
                {(() => {
                    if (location.state.type === 'Projects' && projects) {
                    return (
                        <div className="content">
                            <GoogleProjectsFields display></GoogleProjectsFields>
                            <GoogleProjects data={projects} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken}></GoogleProjects> 
                        </div>
                    )
                    } else if (location.state.type === 'Buckets' && buckets) {
                    return (
                        <div className="content">
                            <GoogleBucketsFields></GoogleBucketsFields>
                            <GoogleBuckets data={buckets} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} projectId={location.state.projectId} ></GoogleBuckets> 
                        
                        </div>
                    )
                    } else if (location.state.type === 'Blobs' && blobs != null) {
                        return (
                            <div className="content">
                                <GoogleFileLinkForm project={location.state.projectId} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} target={targets} showModal={showModal} closeModal={closeSignedURLModal}></GoogleFileLinkForm>
                                
                                <GoogleUploadToS3Form project={location.state.projectId} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} target={targets} showModal={showUploadToS3Modal} closeModal={closeUploadToS3Modal}></GoogleUploadToS3Form>
                                <GoogleUploadToS3FormGroups project={location.state.projectId} bucket={bucket} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} blobs={blobs} showModal={showUploadToS3ModalGroup} closeModal={closeUploadToS3GroupModal}></GoogleUploadToS3FormGroups>
                                <GoogleBlobsFields></GoogleBlobsFields>
                                <GoogleBlobs data={blobs} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} projectId={location.state.projectId} bucketId={location.state.bucketId} blobTargetFunction={blobSelected}></GoogleBlobs>
                            </div>
                        )  
                    } else {
                    return (
                        <div>Loading ... </div>
                    )
                    }
                })()}

            </div>
        </div>
     );
}
 
export default GoogleStorage;