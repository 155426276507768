import './AWSLoginPopup.css'
import {useEffect, useRef} from 'react'

const displayForm = (isPoppedup) => {
   
    document.getElementById('signInForm').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('signInForm').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const AWSLoginPopup = (props) => {


    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    
    useOutsideAlerter(modalRef, isPoppedup, props.closeModal);


    useEffect(() => {
      
        if(props.showModal){
           displayForm(isPoppedup)
        }
    }, [props.showModal])

    


    return ( 
        
        <div>
            <div id="signInForm" className="awssigninmodal" ref={modalRef}>
                <form className="modal-content" >

                  <label for="AccessKey"><b>AccessKey</b></label>
                  <br></br>
                  <input type="text" id="AccessKey" className="browser-default"/>
                  <br></br>
                  <label for="SecretKey"><b>SecretKey</b></label>
                  <br></br>
                  <input type="text" id="SecretKey" className="browser-default"/>
                  <br></br>
                    <div className="clearfix">
                        <button type="submit" className="signupbtn" onClick={props.AWSSignIn}>Upload to S3</button>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default AWSLoginPopup;