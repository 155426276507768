
import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";


const GoogleBlobs = (props) => {
    
    const navigate = useNavigate()
    const [blobs, setBlobs] = useState(null)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [bucketId, setBucketId] = useState(null)
    
    useEffect(() => {
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProjectId(props.projectId)
        setBucketId(props.bucketId)
    }, [])

    useEffect(() => {
        setBlobs(JSON.parse(props.data))
    }, [props.data])

    const accessBlob = (blobName) => {
        if(blobName.match("^.*/$")){
            navigate(`/gcp/storage/${projectId}/${bucketId}/${blobName}`, { state: 
                {   AWSAccessToken: AWSAccessToken,
                    GCPAccessToken: GCPAccessToken,
                    projectId: projectId,
                    bucketId: bucketId,
                    type: 'Blobs',
                    blob: blobName,
                    key: Date.now()
                }
              })
        }
        
    }

    const targetBlob = (e, project, bucket, blob, index) => {
        e.preventDefault();
        props.blobTargetFunction(e.currentTarget, project, bucket, blob, index)
    }

    console.log(blobs)
    return ( 
        <div className="content-list">
            {
                blobs
                ? blobs.map((p, index) => (
                    <div className="content-preview" key={index} onClick={(e) => targetBlob(e, projectId, bucketId, p.name, index)} onDoubleClick={() => accessBlob(p.full_name)}>  
                            <div className="row">
                                <div className="column" >
                                    <p>{p.name}</p>
                                </div>
                                <div className="column" >
                                    <p>{p.size}</p>
                                </div>
                                
                                <div className="column" >
                                    <p>{p.updated}</p>
                                </div>
                            </div>
                    </div>
                )) 
                : null
            }
        </div>
     );
}
 
export default GoogleBlobs;