import { useState, useEffect, useRef} from 'react'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import AWSBuckets from './AWSBuckets';
import AWSBucketsFields from './AWSBucketsFields';
import AWSBlobs from './AWSBlobs';
import AWSBlobsFields from './AWSBlobsFields';
import AWSUserToolbar from './AWSUserToolbar';
import AWSTransferS3toGCS from './AWSTransferS3toGCS';
import AWSTransferS3toBQ from './AWSTransferS3toBQ';
import AWSNavbar from '../AWSNavbar'
import '../../Components/Columns.css';
import '../../Components/User.css'

const iterativeFunction = function (arr, x) {
  

    let start=0, end=arr.length-1;

    
         
    // Iterate while start not meets end
    while (start<=end){
 
        // Find the mid index
        let mid=Math.floor((start + end)/2);
  
        // If element is present at mid, return True
        if (arr[mid]["index"]===x["index"]){
            arr.splice(mid, 1);
            return true;
        } 
 
        // Else look in left or right half accordingly
        else if (arr[mid]["index"] < x["index"])
             start = mid + 1;
        else
             end = mid - 1;
    }
    arr.splice(start, 0, x)
    return false
}


//Load variables/ Loading ... -> run useEffect -> set data -> render entire page
const AWSS3 = () => {
    const location = useLocation();
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [buckets, setBuckets] = useState(null)
    const [blobs, setBlobs] = useState(null)
    const [targets, setTargets] = useState([])
    const [showModalS3toGCS, setShowModalS3toGCS] = useState(false)
    const [showModalS3toBQ, setShowModalS3toBQ] = useState(false)

   
    console.log(location.state)
    useEffect(() => {
        if(location.state.type && ((location.state.type === 'Buckets') || (location.state.type === 'Blobs' && location.state.blob != null))){
            const getBuckets = async () => {
                await axios.get(`/api/aws/s3/buckets`, { params: { accessToken: location.state.AWSAccessToken}}).then(res => {
                    setBuckets(res.data)
                })
            }
            const getBlobs = async () => {
           
                await axios.get(`/api/aws/s3/${location.state.bucketId}`, { params: { accessToken: location.state.AWSAccessToken, blob: location.state.blob}}).then(res => {
                    setBlobs(res.data)
                })
            }
        

            setAWSAccessToken(location.state.AWSAccessToken)
            setGCPAccessToken(location.state.GCPAccessToken)

            if(location.state.type === 'Buckets'){
                getBuckets().catch(console.error);
            } else if(location.state.type === 'Blobs' && location.state.blob != null){
                getBlobs().catch(console.error);
            }
        }
        

    }, [location.state.key])



    const blobSelected = (e, bucket, blob, index) => {
        

        if(iterativeFunction(targets, {bucket, blob, index})){
            e.style.backgroundColor = 'transparent';
        }else{
            e.style.backgroundColor = 'rgba(127, 39, 245, 0.25)';
        }    
        
        setTargets([...targets])
        
    }

    
    const transferButtonTriggerS3toGCS = () => {
        setShowModalS3toGCS(true)

    }

    const closeModalS3toBQ = () => {
        setShowModalS3toGCS(false)
    }

    const transferButtonTriggerS3toBQ = () => {
        setShowModalS3toBQ(true)
    }

    const closeModalS3toGCS = () => {
        setShowModalS3toBQ(false)
    }

    return (
        <div>
            <AWSNavbar GCPAccessToken={GCPAccessToken} setGCPAccessToken={setGCPAccessToken}/>
            <div className="AWSUser">
            <AWSUserToolbar type={location.state.type} GCPAccessToken={GCPAccessToken} AWSAccessToken={AWSAccessToken} bucket={location.state.bucketId} blob={location.state.blob} targets={targets} transferButtonTriggerS3toGCS={transferButtonTriggerS3toGCS} transferButtonTriggerS3toBQ={transferButtonTriggerS3toBQ}></AWSUserToolbar>
                {(() => {
                    if (location.state.type === 'Buckets' && buckets) {
                    return (
                        <div className="content">
                            <AWSBucketsFields></AWSBucketsFields>
                            <AWSBuckets data={buckets} AWSAccessToken={AWSAccessToken}></AWSBuckets> 
                        
                        </div>
                    )
                    } else if (location.state.type === 'Blobs' && blobs != null) {
                        return (
                            <div className="content">
                                <AWSTransferS3toGCS project={location.state.projectId} AWSAccessToken={location.state.AWSAccessToken} GCPAccessToken={GCPAccessToken} targets={targets} showModalS3toGCS={showModalS3toGCS} closeModalS3toGCS={closeModalS3toGCS}></AWSTransferS3toGCS>
                                <AWSTransferS3toBQ project={location.state.projectId} AWSAccessToken={location.state.AWSAccessToken} GCPAccessToken={GCPAccessToken} targets={targets} showModalS3toBQ={showModalS3toBQ} closeModalS3toBQ={closeModalS3toBQ}></AWSTransferS3toBQ>
                                <AWSBlobsFields></AWSBlobsFields>
                                <AWSBlobs data={blobs} AWSAccessToken={AWSAccessToken} GCPAccessToken={GCPAccessToken}  bucketId={location.state.bucketId} blobTargetFunction={blobSelected}></AWSBlobs>
                            </div>
                        )  
                    } else {
                    return (
                        <div>Loading ... </div>
                    )
                    }
                })()}

            </div>
        </div>
     );
}
 
export default AWSS3;