import './GoogleUploadToS3Form.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useLocation } from "react-router-dom";


const displayForm = (isPoppedup) => {
    document.getElementById('uploadToS3Form').style.display ='block'
    isPoppedup.current = true
}

function useOutsideAlerter(ref, isPoppedup, close) {
    useEffect(() => {     

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !isPoppedup.current) {
        document.getElementById('uploadToS3Form').style.display ='none'
        close()
        }else if(isPoppedup.current){
            isPoppedup.current = false
        }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
            

    }, [ref]);
  }


const GoogleUploadToS3Form = (props) => {

    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    const [project, setProject] = useState(null)
    const isPoppedup = useRef(false)
    const modalRef = useRef(null)
    const [target, setTarget] = useState(null)
    const [error, setError] = useState(null)
    useOutsideAlerter(modalRef, isPoppedup, props.closeModal);

    useEffect(() => {
        setError(null)
        setAWSAccessToken(props.AWSAccessToken)
        setGCPAccessToken(props.GCPAccessToken)
        setProject(props.project)
    }, [])

    useEffect(() => {
        if(props.target.length == 1){
            setTarget(props.target[0])
        }
    }, [props.target])


    useEffect(() => {
        
        if(props.showModal){
           displayForm(isPoppedup)
        }
    }, [props.showModal])

    const uploadToS3 = async (e) => {
        e.preventDefault();
        const s3File = document.getElementById('s3FileInput').value
    
        
        if(s3File){
            axios.post('/api/gcloud/storage/upload/s3', null, {
                headers: {
                    "AWSAccessToken": props.AWSAccessToken, 
                    "GCPAccessToken": JSON.stringify(props.GCPAccessToken),
                    "GCSProject": props.project, 
                    "GCSBucket": target['bucket'], 
                    "GCSBlob": target['blob'],
                    "S3File": s3File,
                    'content-type': 'text/json'
                }}
                ).then((res) => {
                    setError(null)
                }
                ).catch((err) => {
                    setError('Error uploading to S3, check if you have AWS credentials')
                }
            )

                    
        }
        
    }

    return ( 
        
        <div>
            <div id="uploadToS3Form" className="modal" ref={modalRef}>
                <form className="modal-content" >
                    <div className="container">
                    <h3>Upload GCS to S3</h3>

                    {
                        Error ? 
                        (<>
                        {/* <button id="buttonFiles" className="button" onClick={getSignedURL}>Create Signed URL</button>  */}
                        <div>{error}</div>                      
                        </>
                        ) :
                        (null)
                    }
                    <p>Please enter a S3 bucket</p>
                    <div className="clearfix">
                        <div>
                            <input id="s3FileInput" type="text" className="browser-default"/>
                        </div>
                        <button type="submit" className="signupbtn" onClick={uploadToS3}>Upload to S3</button>
                    </div>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default GoogleUploadToS3Form;