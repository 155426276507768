

const GoogleButton = (props) => {
    return ( 
        <div className="col s12 m6 offset-m3 center-align">
            <button className="oauth-container btn darken-4 white black-text"  onClick={props.popup_func} style={{textTransform:"none"}}>
                <div className="left">
                    <img width="20px" style={{marginTop:"7px", marginRight: "8px"}} alt="Google sign-in" 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                </div>
                Login with Google
            </button>
    
        </div>
     );
}
 
export default GoogleButton;