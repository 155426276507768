
import { useState, useEffect } from 'react'
import '../../Components/UserToolbar.css'
import axios from 'axios'
import FormData from 'form-data'

const AWSUserToolbar = (props) => {

    const [type, setType] = useState(null)
    const [content, setContent] = useState(null)
    const [target, setTarget] = useState(null)
    const [targets, setTargets] = useState(null)

    useEffect(() => {
        setType(props.type)
    }, [props.type])

    useEffect(() => {
        setTargets(props.targets)
        if(props.targets.length == 1){
            setTarget(props.targets[0])
        }else{
            setTarget(null)
        }
    }, [props.targets])

    useEffect(() => {
        if(content){
            const data = new FormData();
            Array.from(content).forEach(file => {
                data.append('file', file)
            })
            
            console.log(props)
            axios.post('/api/aws/s3/upload/file', data, {
                headers: {
                 "AWSAccessToken": props.AWSAccessToken, 
                 'bucket': props.bucket,
                 'blob': props.blob,
                 'content-type': 'multipart/form-data' 
                }})
        }
    }, [content])

    const uploadFilesButtonPress = () => {
        document.getElementById("inputFiles").value = ''
        document.getElementById("inputFiles").addEventListener("onChange", event => {uploadFiles(event)}, {once: true})
        document.getElementById("inputFiles").click()
    }


    const uploadFiles = (e) => {
        setContent(e.target.files)
    }

    const transferS3toGCS = () => {
        props.transferButtonTriggerS3toGCS()
    }

    const transferS3toBQ = () => {
        props.transferButtonTriggerS3toBQ()
    }


    return (  
        
        <div className="toolbar">
            {(() => {
                    if (type === 'Blobs') {
                    return (
                        <div>
                            <input id="inputFiles" className="input" type="file" onChange={uploadFiles} multiple hidden/>
                            <button id="buttonFiles" className="button" onClick={uploadFilesButtonPress}>Upload Local Files</button>
                            <button id="buttonFiles" className="button" onClick={transferS3toGCS}>Transfer S3 to GCS</button>
                            <button id="buttonFiles" className="button" onClick={transferS3toBQ}>Transfer S3 to BQ</button>
                            {/* {
                            {/* {
                                targets.length > 0 ? 
                                (<button id="buttonFiles" className="button" >Delete Files</button>) :
                                (null)
                            } */}
                            <div className="clear"></div>
                        </div>
                    )
                    } else {
                    return (
                        null
                    )
                    }
                })()}
            
        </div>
    );
}
 
export default AWSUserToolbar;