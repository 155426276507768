//External imports
import GoogleButton from './GoogleButton';
import { useState, useEffect} from 'react'
import axios from 'axios'
import {useNavigate, useLocation} from "react-router-dom";

const loadScript = (src) =>
new Promise((resolve, reject) => {
  if (document.querySelector(`script[src="${src}"]`)) return resolve()
  const script = document.createElement('script')
  script.src = src
  script.onload = () => resolve()
  script.onerror = (err) => reject(err)
  document.body.appendChild(script)
})

const GoogleLogin = (props) => {
  const navigate = useNavigate()
  const location = useLocation();

  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData')
    ? JSON.parse(localStorage.getItem('loginData'))
    : null
  )


  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
    ? JSON.parse(localStorage.getItem('accessToken'))
    : null
  )


  useEffect(() => {
      if(loginData && !accessToken){
        loginData.requestCode()
      }
  }, [loginData])
  

  useEffect(() => {
    if(accessToken){
      if(location.pathname === '/login' || location.pathname === '/'){
        navigate('/gcp/storage', { state: 
          
          { GCPAccessToken: accessToken.data,
            AWSAccessToken: location.state && location.state.AWSAccessToken,
            type: 'Projects',
            key: Date.now()
          }
        })
      }else{
        props.setGCPAccessToken(accessToken.data)
      }
    }
}, [accessToken])

  function signIn(){
    console.log('hello')
    const src = 'https://accounts.google.com/gsi/client'
    const id = process.env.REACT_APP_GOOGLE_CLIENT_ID
    loadScript(src)
      .then(async () => {
        /*global google*/
        const auth = await google.accounts.oauth2.initCodeClient({
          client_id: id,
          scope: "https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/devstorage.full_control",
          callback: handleCredentialResponse,
        })
        setLoginData(auth)
      })
      .catch(console.error)
      
    
    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }

  

  async function handleCredentialResponse(response) {
    const token = response.code
    const scope = response.scope
    const res = await axios.post('/api/gcloud/login', { "token": token, "scope": scope }, {
        headers: {
            'content-type': 'text/json'
        }
    });
    
    setAccessToken(res)
  }

  return (
    <div className='GoogleLogin'>
          <div> 
            <GoogleButton popup_func={signIn}></GoogleButton>
          </div>
    </div>
  )
}

export default GoogleLogin