import { useState, useEffect, useRef} from 'react'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import GCPNavbar from '../GCPNavbar'
import GoogleProjects from './GoogleProjects';
import GoogleProjectsFields from './GoogleProjectsFields';
import GoogleBuckets from './GoogleBuckets';
import GoogleBucketsFields from './GoogleBucketsFields';
import GoogleBlobs from './GoogleBlobs';
import GoogleBlobsFields from './GoogleBlobsFields';
import GoogleBigQueryToolbar from './GoogleBigQueryToolbar';
import GoogleTransferBQtoS3 from './GoogleTransferBQtoS3';
import '../../Components/Columns.css';
import '../../Components/User.css'

const iterativeFunction = function (arr, x) {
  

    let start=0, end=arr.length-1;

    
         
    // Iterate while start not meets end
    while (start<=end){
 
        // Find the mid index
        let mid=Math.floor((start + end)/2);
  
        // If element is present at mid, return True
        if (arr[mid]["index"]===x["index"]){
            arr.splice(mid, 1);
            return true;
        } 
 
        // Else look in left or right half accordingly
        else if (arr[mid]["index"] < x["index"])
             start = mid + 1;
        else
             end = mid - 1;
    }
    arr.splice(start, 0, x)
    return false
}

//Load variables/ Loading ... -> run useEffect -> set data -> render entire page
const GoogleBigQuery = () => {
    const location = useLocation();
    const [projects, setProjects] = useState(null)
    const [datasets, setDatasets] = useState(null)
    const [tables, setTables] = useState(null)
    const [targets, setTargets] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [AWSAccessToken, setAWSAccessToken] = useState(null)
    const [GCPAccessToken, setGCPAccessToken] = useState(null)
    

    useEffect(() => {
        if(location.state.type && ((location.state.type === 'Projects' || location.state.type === 'Datasets') || (location.state.type === 'Tables' && location.state.blob != null))){
            const getProjects = async () => {
        
                await axios.get('/api/gcloud', { params: { accessToken: location.state.GCPAccessToken}}).then(res => {
                    setProjects(res.data)
                    
                })
            }
            const getDatasets = async () => {
                await axios.get(`/api/gcloud/bigquery/${location.state.projectId}`, { params: { accessToken: location.state.GCPAccessToken}}).then(res => {
                    setDatasets(res.data)
                })
            }
            const getTables = async () => {
                await axios.get(`/api/gcloud/bigquery/${location.state.projectId}/${location.state.datasetId}`, { params: { accessToken: location.state.GCPAccessToken}}).then(res => {
                    setTables(res.data)
                })
            }

            setAWSAccessToken(location.state.AWSAccessToken)
            setGCPAccessToken(location.state.GCPAccessToken)
        
            if(location.state.type === 'Projects'){
                getProjects().catch(console.error);
            } else if(location.state.type === 'Datasets'){
                getDatasets().catch(console.error);
            } else if(location.state.type === 'Tables' && location.state.blob != null){
                getTables().catch(console.error);
            }
        }

    }, [location.state.key])


    const TableSelected = (e, tableId, index) => {
        
        if(iterativeFunction(targets, {tableId, index})){
            e.style.backgroundColor = 'transparent';
        }else{
            e.style.backgroundColor = 'rgba(127, 39, 245, 0.25)';
        }    
        
        setTargets([...targets])
    }

    
    const transferButtonTrigger = () => {
        setShowModal(true)
    }

    const closeSignedURLModal = () => {
            setShowModal(false)
    }

    return (
        <div>
            <GCPNavbar AWSAccessToken={AWSAccessToken} setAWSAccessToken={setAWSAccessToken}/>
            <div className="GoogleBigQuery">
            <GoogleBigQueryToolbar type={location.state.type} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} target={targets} transferButtonTrigger={transferButtonTrigger}></GoogleBigQueryToolbar>
                {(() => {
                    if (location.state.type === 'Projects' && projects) {
                    return (
                        <div className="content">
                            <GoogleProjectsFields display></GoogleProjectsFields>
                            <GoogleProjects data={projects} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken}></GoogleProjects> 
                        </div>
                    )
                    } else if (location.state.type === 'Datasets' && datasets) {
                    return (
                        <div className="content">
                            <GoogleBucketsFields></GoogleBucketsFields>
                            <GoogleBuckets data={datasets} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} projectId={location.state.projectId} ></GoogleBuckets> 
                        
                        </div>
                    )
                    } else if (location.state.type === 'Tables' && tables != null) {
                        return (
                            <div className="content">
                                <GoogleTransferBQtoS3 project={location.state.projectId} AWSAccessToken={AWSAccessToken} GCPAccessToken={GCPAccessToken} targets={targets} showModal={showModal} closeModal={closeSignedURLModal}></GoogleTransferBQtoS3>
                                <GoogleBlobsFields></GoogleBlobsFields>
                                <GoogleBlobs data={tables} AWSAccessToken={AWSAccessToken} GCPAccessToken={location.state.GCPAccessToken} projectId={location.state.projectId} datasetId={location.state.datasetId} blobTargetFunction={TableSelected}></GoogleBlobs>
                            </div>
                        )  
                    } else {
                    return (
                        <div>Loading ... </div>
                    )
                    }
                })()}

            </div>
        </div>
     );
}
 
export default GoogleBigQuery;